import { AxiosError } from 'axios'
import { Assessment, ClaimStatus, EvaluationStatus, FileExtension, PlanStatus, ThreatStatus } from 'features/incidents/components/enums'
import { Attribute, OpenFullscreenProps, Orgc, PolicyFileInfo, Tag } from 'features/incidents/components/type'
import { edrScheme, patchSchema, schema } from 'features/profile/components/ServicesActivationModal'
import { calculateDiscount } from 'features/quote/components/GetAQuoteProcess/ServiceFormBase'
import { InsuranceData, TotalDiscountProps } from 'features/quote/components/type'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import moment from 'moment'
import { DependencyList, useEffect } from 'react'
import * as Yup from 'yup'
import { ServicesEnum } from './enum'
import { readContractProps } from 'lib/wagmi/ContractRead'

export const getFileInfoFromUrl = (url: string | URL | undefined): PolicyFileInfo | undefined => {
  const fileURL: string | URL = String(url)
  const { pathname } = new URL(fileURL)
  const index = pathname.lastIndexOf('/')
  const _url = pathname.substring(index + 1)

  if (pathname.endsWith('.html') || pathname.endsWith('.pdf')) {
    return { type: FileExtension.PDF, _url }
  } else if (pathname.endsWith('.docx')) {
    return { type: FileExtension.DOCX, _url }
  } else if (pathname.endsWith('.png') || pathname.endsWith('.jpeg') || pathname.endsWith('.jpg')) {
    return { type: FileExtension.IMAGE, _url }
  }
}

export const openInNewTab = (url: string | URL | undefined) => {
  window.open(url, '_blank')
}

export const openFullscreen = <T extends HTMLElement>({ elementRefs, idx }: OpenFullscreenProps<T>) => {
  const elem = elementRefs.current[idx]
  if (elem) {
    elem.requestFullscreen()
  }
}

export const addThousandsOperator = (value: number) => {
  return String(value.toString()).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const addNumeral = (number: number) => {
  if (number < 1e3) return number
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(3) + ' K'
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(7) + ' M'
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(7) + ' B'
  if (number >= 1e12) return +(number / 1e12).toFixed(7) + ' T'
}
export const addEllipsis = (string: string, characters = 4, inCenter = true) => {
  if (inCenter) {
    if (string?.length > characters * 2) {
      return string.substr(0, characters) + '...' + string.substr(string.length - characters, string.length)
    }
  } else {
    if (string?.length > characters) {
      return string.substr(0, characters) + '...'
    }
  }
  return string
}
export const getUpperCase = (string: string, isValue?: boolean) => {
  let value = ''
  string.split('.').forEach(sentene => {
    value = value + `${sentene.trim().slice(0, 1).toUpperCase()}${sentene.trim().slice(1)}${isValue ? '' : '.'}`
  })
  return value
}

export const errorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (typeof error.response?.data?.message === 'string') {
      return error.response?.data?.message
    } else {
      return error.response?.data?.message[0]
    }
  }
}

export const convertImagesToBase64 = async (imageSrcs: string[]): Promise<string[]> => {
  const dataUrls: string[] = []
  let count = 0

  const loadNextImage = () => {
    return new Promise<void>(resolve => {
      if (count >= imageSrcs.length) {
        resolve()
        return
      }

      const image = new Image()
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.height = image.naturalHeight
        canvas.width = image.naturalWidth
        ctx?.drawImage(image, 0, 0)
        const dataUrl = canvas.toDataURL()
        dataUrls.push(dataUrl)
        count++
        loadNextImage().then(() => {
          resolve()
        })
      }
      image.src = imageSrcs[count]
    })
  }

  await loadNextImage()

  return dataUrls
}

export const convertDocUrlsToBase64 = async (docUrls: string[] | string): Promise<string[]> => {
  const base64Urls: string[] = []
  if (docUrls) {
    for (const url of docUrls) {
      if (url.endsWith('jpg') || url.endsWith('jpeg') || url.endsWith('png')) {
        const convertedUrl = await convertImagesToBase64([url])
        base64Urls.push(...convertedUrl)
      } else {
        base64Urls.push(url)
      }
    }
  }
  return base64Urls
}

export const processAcceptText = (acceptText: string) => {
  if (acceptText.charAt(acceptText.length - 1) === 'e') {
    return acceptText.slice(0, -1)
  }
  return acceptText
}

export const downloadPdfDocument = (pdfElement: HTMLElement[], callback?: () => void, setDownloadStatus?: (value: boolean) => void, name?: string) => {
  const options = {
    scale: 2, // Increase the scale value for higher resolution
    backgroundColor: '#0d1023',
  }

  const pdf = new jsPDF('p', 'mm', 'a4', true)
  pdf.setFillColor(13, 16, 35)
  pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')

  let currentPage = 1 // Declare and initialize currentPage to 1

  const processElement = (element: HTMLElement, index: number) => {
    const elementRect = element.getBoundingClientRect()
    const padding = 10
    const aspectRatio = elementRect.width / elementRect.height

    html2canvas(element, {
      ...options,
      scrollY: -window.scrollY,
    }).then(canvas => {
      // Check if a new page is needed
      if (index > 0) {
        pdf.addPage()
        pdf.internal.pageSize.height = pdf.internal.pageSize.width / aspectRatio // update the page height
        currentPage++ // Increment currentPage if a new page is added
      }

      pdf.setFillColor(13, 16, 35)
      pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')

      // Calculate the position and size of the image on the current page
      const imageTopPosition = padding
      const elementWidth = pdf.internal.pageSize.width - padding * 2
      const elementHeight = elementWidth / aspectRatio

      let imageWidth, imageHeight

      if (elementHeight <= pdf.internal.pageSize.height - padding * 2) {
        imageWidth = elementWidth
        imageHeight = elementHeight
      } else {
        imageHeight = pdf.internal.pageSize.height - padding * 2
        imageWidth = imageHeight * aspectRatio
      }

      const imageLeftPosition = (pdf.internal.pageSize.width - imageWidth) / 2

      pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', imageLeftPosition, imageTopPosition, imageWidth, imageHeight, undefined, 'FAST')

      // If this is the last element, save the PDF
      if (index === pdfElement.length - 1) {
        setTimeout(() => {
          pdf.save(name ? `${name}_${currentPage}.pdf` : `Stats-Report.pdf`)
          setDownloadStatus?.(true)
          if (callback) {
            callback()
          }
        }, 500)
      } else {
        processElement(pdfElement[index + 1], index + 1)
      }
    })
  }

  // Start processing the first element
  processElement(pdfElement[0], 0)
}

export function useDebounceEffect(fn: () => void, waitTime: number, deps: DependencyList = []) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fn()
    }, waitTime)

    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const convertPdfToImage = (pdfUrl: string, callback: (imageUrl: string) => void): void => {
  const pdf = new jsPDF()

  pdf.addImage(pdfUrl, 'JPEG', 0, 0, 0, 0)
  const imageData = pdf.output('datauristring')

  const image = new Image()
  image.src = imageData

  image.onload = () => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      canvas.width = image.width
      canvas.height = image.height
      context.drawImage(image, 0, 0)

      const imageUrl = canvas.toDataURL('image/png')
      callback(imageUrl)
    } else {
      // eslint-disable-next-line no-console
      console.error('Could not get canvas context.')
    }
  }
}

export const downloadPDF = (url: string | URL | undefined, fileName = 'example.pdf') => {
  if (url) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        link.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => console.error('Error fetching PDF:', error))
  }
}
export const ClaimMsg = (claimStatus: string) => {
  if (claimStatus === ClaimStatus.CLAIM_RAISED) {
    return 'Claim has been Initiated by the User.'
  } else if (claimStatus === ClaimStatus.APPROVED) {
    return 'Claim has been Approved.'
  } else if (claimStatus === ClaimStatus.DISAPPROVED) {
    return 'Claim has been Disapproved.'
  } else if (claimStatus === ClaimStatus.ACCEPTED) {
    return 'Claim has been Accepted.'
  } else if (claimStatus === ClaimStatus.REJECTED) {
    return 'Claim has been Rejected.'
  } else if (claimStatus === ClaimStatus.RE_EVALUATE) {
    return 'Claim has been Re-Evaluated by the User.'
  }
}
export const ProgressId = (threatStatus: string) => {
  if (threatStatus === ThreatStatus.INCIDENT_DETECTED) {
    return 0
  } else if (threatStatus === ThreatStatus.INCIDENT_VERIFIYING) {
    return 1
  } else if (threatStatus === ThreatStatus.CLAIM_INITIATED) {
    return 2
  } else if (threatStatus === ThreatStatus.INSURANCE_CONFIRMATION) {
    return 3
  } else if (threatStatus === ThreatStatus.CLAIM_RESOLVED) {
    return 4
  } else return -1
}
export const ClaimColor = (claimStatus: string) => {
  if (claimStatus === ClaimStatus.APPLICABLE || claimStatus === Assessment.COMING_SOON) {
    return 'infoBg'
  } else if (claimStatus === ClaimStatus.APPROVED || claimStatus === ClaimStatus.ACCEPTED || claimStatus === Assessment.CONNECT) {
    return 'successBg'
  } else if (claimStatus === ClaimStatus.DISAPPROVED || claimStatus === ClaimStatus.REJECTED || claimStatus === ClaimStatus.NOT_APPLICABLE) {
    return 'errorBg'
  } else if (claimStatus === ClaimStatus.RE_EVALUATE || claimStatus === ClaimStatus.CLAIM_RAISED || status === PlanStatus.OVERDUE) {
    return 'warningBg'
  } else if (claimStatus === Assessment.ADD) {
    return 'primarysBg'
  }
}
export const ReturnTags = (tag: string) => {
  if (tag == 'header-one') return 'h1'
  else if (tag == 'header-two') return 'h2'
  else if (tag == 'header-three') return 'h3'
  else if (tag == 'header-four') return 'h4'
  else if (tag == 'header-five') return 'h5'
  else if (tag == 'header-six') return 'h6'
  else if (tag == 'blockquote') return 'blockquote'
  else if (tag == 'code') return 'code'
  else if (tag == 'unstyled') return 'p'
  else if (tag == 'image') return 'img'
  else if (tag === 'IMAGE') return 'IMAGE'
  else if (tag === 'EMBEDDED_LINK') return 'EMBEDDED_LINK'
  else if (tag === 'LINK') return 'LINK'
}

export const getDefaultValuesInsuranceForm = (insuranceObj: InsuranceData) => ({
  q1: {
    label: insuranceObj?.typesOfSensitiveData || null,
    value: insuranceObj?.typesOfSensitiveData || null,
  },
  q2: insuranceObj?.annualRevenue || null,
  q3: {
    label: insuranceObj?.experiencedCyberAttack || null,
    value: insuranceObj?.experiencedCyberAttack || null,
  },
  q4: {
    label: insuranceObj?.conductCyberAttackTraining || null,
    value: insuranceObj?.conductCyberAttackTraining || null,
  },
  q5: {
    label: insuranceObj?.howManyEmployees || null,
    value: insuranceObj?.howManyEmployees || null,
  },
  // q6: {
  //   label: insuranceObj?.backupAtSecondaryLocation || null,
  //   value: insuranceObj?.backupAtSecondaryLocation || null,
  // },
  // q7: {
  //   label: insuranceObj?.totalServersCompanyHave || null,
  //   value: insuranceObj?.totalServersCompanyHave || null,
  // },
  // q8: {
  //   label: insuranceObj?.totalEndPointsCompanyHave || null,
  //   value: insuranceObj?.totalEndPointsCompanyHave || null,
  // },
  // q9: insuranceObj?.isPerformVulnerabilityAndPatching === null ? '' : insuranceObj?.isPerformVulnerabilityAndPatching.toString(),
  // q10: insuranceObj?.isPiiStoredEncrypted === null ? '' : insuranceObj?.isPiiStoredEncrypted.toString(),

  // q11: insuranceObj?.isMeasuresImplementedToProtect === null ? '' : insuranceObj?.isMeasuresImplementedToProtect.toString(),
  // q12: {
  //   label: insuranceObj?.employeesUndergoAwarenessTraining || null,
  //   value: insuranceObj?.employeesUndergoAwarenessTraining || null,
  // },
  // q13: insuranceObj?.isSecondaryConfirmationProcessImplemented === null ? '' : insuranceObj?.isSecondaryConfirmationProcessImplemented.toString(),
  // q14: insuranceObj?.isNetworkSegmented === null ? '' : insuranceObj?.isNetworkSegmented.toString(),
  // q15: {
  //   label: insuranceObj?.organizationRestrictAuthorizedUsers || null,
  //   value: insuranceObj?.organizationRestrictAuthorizedUsers || null,
  // },
  // q16: {
  //   label: insuranceObj?.passwordPolicyImplemented || null,
  //   value: insuranceObj?.passwordPolicyImplemented || null,
  // },
  // q17: {
  //   label: insuranceObj?.multifactorAuthenticationImplemented || null,
  //   value: insuranceObj?.multifactorAuthenticationImplemented || null,
  // },
  // q18: insuranceObj?.isPacisProtected === null ? '' : insuranceObj?.isPacisProtected.toString(),
})

export const CoveSessionStatus = (value: string) => {
  switch (value) {
    case '1':
      return 'In process'
      break
    case '2':
      return 'Failed'
      break
    case '3':
      return 'Aborted'
      break
    case '5':
      return 'Completed'
      break
    case '6':
      return 'Interrupted'
      break
    case '7':
      return 'NotStarted'
      break
    case '8':
      return 'CompletedWithErrors'
      break
    case '9':
      return 'InProgressWithFaults'
      break
    case '10':
      return 'OverQuota'
      break
    case '11':
      return 'NoSelection'
      break
    case '12':
      return 'Restarted'
      break
    case '0':
      return 'No Data'
      break
    default:
      return 'Unknown Status'
  }
}
export const CoveRecoveryStatus = (value: string) => {
  if (value == '1') return 'On'
  else if (value == '0') return 'Off'
}
export const Last28DaysStatus = (value: string) => {
  switch (value) {
    case '0':
      return 'grey'
      break
    case '5':
      return 'green'
      break
    case '8':
      return 'orange'
      break
    default:
      return 'red'
  }
}
export const Last28DaysDateCount = (value: string, index: number) => {
  const updatedDate = moment(value).subtract(index, 'days')
  return updatedDate.format('MM-DD-YYYY')
}
export const TotalDiscountApplied = (props: TotalDiscountProps) => {
  const { services, totalDiscount, setHasServices, setHadDiscount } = props
  if (services) {
    if (services && services.length > 1) {
      const appliedDiscount = calculateDiscount(services, totalDiscount)
      setHasServices(`${appliedDiscount}%`)
    }
    setHadDiscount(services.length > 1)
  }
}
export const getFormSchemaAndFields = (modalType: ServicesEnum) => {
  switch (modalType) {
    case ServicesEnum.EDR:
      return {
        schema: edrScheme,
        fields: [
          { name: 'sentinelOneApiKey', label: 'Sentinel One API Key', placeholder: 'Enter Sentinel One API Key' },
          {
            name: 'sentinelOneConsoleUrl',
            label: 'Sentinel One URL ',
            placeholder: 'Enter Sentinel One URL ',
          },
        ],
        title: 'Connect EDR',
        open: true,
      }
    case ServicesEnum.COVE_DATA:
      return {
        schema: schema,
        fields: [
          { name: 'userName', label: 'Email', placeholder: 'Enter Email' },
          { name: 'password', label: 'Password', placeholder: 'Enter Password' },
        ],
        title: 'Connect Cove',
        open: true,
      }
    case ServicesEnum.N_SIGHT_PATCH:
      return {
        schema: patchSchema,
        fields: [{ name: 'apiKey', label: 'Api Key', placeholder: 'Enter API Key' }],
        title: 'Connect Patch',
        open: true,
      }
    default:
      return { schema: Yup.object(), fields: [] }
  }
}
export function convertFirstLetterToUpperCase(value: string) {
  if (typeof value !== 'string' || value.length === 0) {
    return value
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const formatServiceName = (inputString: string) => {
  const words = inputString.split(' ')

  // Capitalize the first letter of each word and join with spaces
  const formattedWords = words.map(word =>
    word
      //if hyphen then sub word and map other wise the word is treated as a subword
      .split('-')
      .map(subword => subword.charAt(0).toUpperCase() + subword.slice(1).toLowerCase())
      .join('-')
  )

  return formattedWords.join(' ')
}
//contract calling fn bases on the primaryservice
export const contractFunctionName = (serviceName: string) => {
  switch (serviceName) {
    case EvaluationStatus.EDR:
      return 'edrClosedClaims'
    case EvaluationStatus.COVE:
      return 'coveClosedClaims'
    case EvaluationStatus.NSIGHT:
      return 'patchClosedClaims'
    default:
      return 'edrClosedClaims'
  }
}

export const ContractDataBasedOnPrimaryService = (serviceName: string, contract: readContractProps) => {
  switch (serviceName) {
    case EvaluationStatus.COVE:
      return [
        {
          DeviceName: contract?.AN,
          DeviceId: contract?.deviceId && parseInt(contract?.deviceId),
        },
      ]
    case EvaluationStatus.EDR:
      return [
        {
          'Sentinel One Hash': contract?.threatHash,
          'Classification': contract?.classification,
        },
      ]
    case EvaluationStatus.NSIGHT:
      return [
        {
          DeviceName: contract?.deviceName,
          DeviceId: contract?.deviceId && parseInt(contract?.deviceId),
        },
      ]
    default:
      return []
  }
}

export function convertMISPApiResponse(response: { response: { Attribute: Attribute[] } }): { Orgc: Orgc; Tag: Tag[] }[] {
  const galaxyImageStaticDataArray: { Orgc: Orgc; Tag: Tag[] }[] = []

  // Extracting unique Orgc entries and their associated tags
  const uniqueOrgcMap = new Map<string, { Orgc: Orgc; Tag: Tag[] }>()
  response?.response?.Attribute?.forEach(attr => {
    const orgcId = attr.Event.Orgc.id
    if (!uniqueOrgcMap.has(orgcId)) {
      uniqueOrgcMap.set(orgcId, {
        Orgc: {
          id: orgcId,
          name: attr.Event.Orgc.name,
          uuid: attr.Event.Orgc.uuid,
          local: attr.Event.Orgc.local,
        },
        Tag: [],
      })
    }

    // Adding unique tags to each Orgc
    attr?.Tag?.forEach(tag => {
      if (!uniqueOrgcMap.get(orgcId)?.Tag.some(existingTag => existingTag.id === tag.id)) {
        uniqueOrgcMap.get(orgcId)?.Tag.push({
          id: tag.id,
          name: tag.name,
          colour: tag.colour,
          is_galaxy: tag.is_galaxy,
          local: tag.local,
          inherited: tag.inherited,
        })
      }
    })
  })

  // Converting map values to array for the final output
  uniqueOrgcMap?.forEach(orgcData => {
    galaxyImageStaticDataArray.push(orgcData)
  })

  return galaxyImageStaticDataArray
}
export function countOccurrences(str: string) {
  // Use a regular expression to find all occurrences of "click here" (case-insensitive)
  const matches = str.match(/click here/gi)

  // Return the number of matches found or 0 if no matches are found
  return matches ? matches.length : 0
}
